import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/FhGkegt8SMc">
    <SEO title="Character Counts - Romans: Practice" />
  </Layout>
)

export default SermonPost
